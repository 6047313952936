<template>
  <div class="text-center relative">
      <v-card class="text-center" :flat="vertical">
        <v-card-actions v-if="!vertical">
          <v-spacer></v-spacer>
          <v-btn
            v-if="$hasRole('ROLE_CONSULTANT') && sheet && selectedTargetFields && selectedTargetFields.length"
            color="success"
            @click="startBulk()"
          >{{ $t('edit') }}</v-btn>
          <v-btn
            v-if="sheet"
            color="error"
            @click="sheet = false"
          >{{ $t('cancel') }}</v-btn>
        </v-card-actions>
        <v-toolbar
            v-else
            tile
            color="primary"
            dark
            flat
            :height="toolbarHeight"
        >
          <v-toolbar-title>{{ $tc('vvtList.more.actions.bulk.title', records.length) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom color="success">
              <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      ripple
                      tile
                      class="ma-0 mr-3"
                      color="success"
                      @click="startBulk()"
                      :disabled="!$hasRole('ROLE_CONSULTANT') || !sheet || !selectedTargetFields || !selectedTargetFields.length"
                  >
                      <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                      <span v-if="$vuetify.breakpoint.mdAndUp" v-t="'edit'" class="pl-3"></span>
                  </v-btn>
              </template>
              <span>{{ $tc('vvtList.more.actions.bulk.title', records.length) }}</span>
          </v-tooltip>
          <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="white"
                      outlined
                      fab
                      small
                      :elevation="2"
                      class="ma-2"
                      @click="sheet = false"
                  >
                      <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
              </template>
              <span v-t="'cancel'"></span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pa-3">
          <v-alert 
            v-if="!hasOnlyRecordsInSameCompany"
            type="info"
            icon="mdi-information-outline"
            outlined
            border="left">
            {{ $t('vvtList.more.actions.bulk.sameCompanyInfo') }}
          </v-alert>
          <v-container fluid class="pa-0">
            <v-row>
              <v-col v-if="!hideRecords" cols="12" :lg="vertical ? 12 : 5">
                <SimpleRecordList :table="vertical" :records="records" />
              </v-col>
              <v-col v-if="!hideRecords" cols="12" :lg="vertical ? 12 : 2">
                <v-icon color="primary" x-large>mdi-format-list-checks</v-icon>
              </v-col>
              <v-col cols="12" :lg="hideRecords ? 12 : vertical ? 12 : 5">
                <v-autocomplete
                  v-model="selectedTargetFields"
                  :items="targetFieldItems"
                  :disabled="!$hasRole('ROLE_CONSULTANT')"
                  chips
                  :label="$t('vvtList.more.actions.bulk.targetFields.label')"
                  multiple
                  solo
                  :flat="flat"
                  item-text="name"
                  item-value="fieldName"
                  hide-details
                  :outlined="vertical"
                  clearable
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
    </v-card>
    <v-overlay
      absolute
      :color="color"
      :value="disabled"
    >
      <slot name="overlayContent"></slot>
    </v-overlay>
    <v-dialog
      v-model="bulkEditDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <Editor :canSave="canSave" :sameCompanyId="hasOnlyRecordsInSameCompany ? records[0].companyId : null" @modified="canSave = true" :selectedTargetFields="mappedSelectedTargetFields" :records="records" @close="bulkEditDialog = false" @bulkDone="bulkDone" @clearSelectedTargetFields="selectedTargetFields = []" />
    </v-dialog>
  </div>
</template>

<script>
const SimpleRecordList = () => import(/* webpackChunkName: "SimpleRecordList" */ '@/components/vvt/List/SimpleRecordList.vue');
const Editor = () => import(/* webpackChunkName: "VvtListBulkEdit" */ './Editor.vue');
import processingActivityMixin from '@/mixins/processingActivityMixin';

export default {
  components: {
    SimpleRecordList,
    Editor
  },
  mixins: [processingActivityMixin],
  props: {
    bulkEditSheet: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideRecords: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    records: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: null
    },
    toolbarHeight: {
        type: Number,
        default: 64
    }
  },
  i18n: {
      messages: {
          en: require('@/locales/vvt/List/en.json'),
          de: require('@/locales/vvt/List/de.json'),
      },
  },
  data () {
    return {
      selectedTargetFields: [],
      bulkEditDialog: false,
      canSave: false
    }
  },
  computed: {
    mappedSelectedTargetFields () {
      return this.processingActivityFields.filter(x => this.selectedTargetFields.includes(x.fieldName)).filter(this.allowedBulkFieldsFilter);
    },
    targetFieldItems () {
      return this.processingActivityFields.filter(this.allowedBulkFieldsFilter);
    },
    hasOnlyRecordsInSameCompany() {
      if(!this.records || !this.records.length) {
        return null;
      }
      return this.records.every(x => x.companyId === this.records[0].companyId)
    },
    sheet: {
      get () {
        return this.bulkEditSheet;
      },
      set (val) {
        this.$emit('setBulkEditSheet', val);
      }
    }
  },
  methods: {
    allowedBulkFieldsFilter(x) {
      if(this.$objectPropertyExists(x, 'enabledForBulkEdit') && x.enabledForBulkEdit) {
        if(!this.$objectPropertyExists(x, 'bulkEditRestriction') || !Array.isArray(x.bulkEditRestriction)) {
          return true;
        }
        if(x.bulkEditRestriction.includes('onlyRecordsForSameCompany')) {
          return this.hasOnlyRecordsInSameCompany;
        }
      }
      return false;
    },
    startBulk () {
      this.bulkEditDialog = true;
    },
    bulkDone () {
      this.bulkEditDialog = false;
      this.sheet = false;
      this.$emit('multipleActionSuccess', 'vvtList.more.actions.bulk.successMessage');
    }
  },
  created () {
    this.setLocale(this.$i18n.locale);
  },
  watch: {
    bulkEditDialog() {
      this.canSave = false;
    }
  }
}
</script>
